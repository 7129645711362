// src/features/notifications/services/notifications.api.ts

import {client} from '@/lib/httpClient';
import type {NotificationFilters, NotificationsListResponse,} from '@/types/notification.types';
import {ActionStatus} from "@/types/api.types";

export const notificationsApi = {
    getUserNotifications: async (filters?: NotificationFilters) => {
        const response = await client.get<NotificationsListResponse>(
            '/api/v1/notifications',
            {params: filters}
        );
        return response.data;
    },

    deleteNotificationStatus: async ({id}: { id: string }) : Promise<ActionStatus> => {
        const response = await client.delete<ActionStatus>(
            `/api/v1/notifications/${id}`,
        );
        return response.data;
    }
};
