
export const isClient = (roles: string[]): boolean => {
    return roles.includes('client_administrator') || roles.includes('client_manager') || roles.includes('client_employee');
};

export const isMindsysExternalPartner = (roles: string[]): boolean => {
    return roles.includes('tech');
}

export const isMindsys = (roles: string[]): boolean => {
    return roles.includes('administrator') || roles.includes('helpdesk') || roles.includes('manager') || roles.includes('agent');
}

