import React from 'react';
import {Pencil} from 'lucide-react';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import {ProductCheckboxGroup} from "@/components/ui/Checkbox/ProductCheckboxGroup";
import {Dialog as HeadlessDialog} from "@headlessui/react";
import type {Ticket, UpdateTicketPayload} from '@/types/ticket.types';
import {useUpdateTicketMutation} from "@/components/features/tickets/services/tickets.mutations";
import {useGetCompanyProductsQuery} from "@/components/features/companies/services/companies.queries";
import useUserStore from "@/stores/useUserStore";
import {isMindsysExternalPartner} from "@/utils/auth.utils";

interface EditTicketDialogProps {
    ticket: Ticket;
    // companyProducts: CompanyProduct[];
}

const EditTicketDialog: React.FC<EditTicketDialogProps> = ({ ticket }) => {
    const [open, setOpen] = React.useState(false);
    const userStore = useUserStore(state => state.user);
    const isMindsysExternal = isMindsysExternalPartner(userStore.roles);
    const updateMutation = useUpdateTicketMutation(ticket.ticket_id);
    const { data: companyProducts } = useGetCompanyProductsQuery(
        ticket.company.company_id
    );

    // Initialize selected products using product_client_id from ticket products
    const selectedProductIds = React.useMemo(() =>
            ticket.products.map(tp => tp.product_client.product_client_id),
        [ticket.products]
    );


    const [formData, setFormData] = React.useState<UpdateTicketPayload>({
        category_id: ticket.category.category_id,
        priority_id: ticket.priority.priority_id,
        products: selectedProductIds
    });


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        updateMutation.mutate(formData, {
            onSuccess: () => {
                setOpen(false);
            }
        });
    };

    return (
        <>
            {/* Trigger Button */}
            <Button
                variant={ButtonVariant.OUTLINE}
                size={ButtonSize.SM}
                onClick={() => setOpen(true)}
                title="Επεξεργασία"
            >
                <Pencil className="h-4 w-4" />
            </Button>

            {/* Dialog */}
            <HeadlessDialog
                open={open}
                onClose={() => setOpen(false)}
                className="relative z-50"
            >
                {/* Backdrop */}
                <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                {/* Dialog positioning */}
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <HeadlessDialog.Panel className="mx-auto max-w-[500px] w-full rounded-lg bg-white p-6">
                        <HeadlessDialog.Title className="text-lg font-medium leading-6 text-gray-900 mb-4">
                            Επεξεργασία Ticket #{ticket.ticket_number}
                        </HeadlessDialog.Title>

                        <form onSubmit={handleSubmit} className="space-y-6">
                            <CategoryDropdown
                                required
                                value={formData.category_id}
                                onChange={(value) => setFormData(prev => ({
                                    ...prev,
                                    category_id: value
                                }))}
                            />

                            <PriorityDropdown
                                required
                                value={formData.priority_id}
                                onChange={(value) => setFormData(prev => ({
                                    ...prev,
                                    priority_id: value
                                }))}
                            />

                            {!isMindsysExternal && <div>
                                <h3 className="text-sm font-medium mb-3">Σχετικά Προϊόντα</h3>
                                <ProductCheckboxGroup
                                    products={companyProducts ?? []}
                                    selectedProducts={formData.products}
                                    onChange={(selected) => setFormData(prev => ({
                                        ...prev,
                                        products: selected
                                    }))}
                                    isLoading={false}
                                />
                            </div>}

                            <div className="flex justify-end space-x-3 pt-4">
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    onClick={() => setOpen(false)}
                                    type={ButtonType.BUTTON}
                                    size={ButtonSize.SM}
                                >
                                    Ακύρωση
                                </Button>
                                <Button
                                    variant={ButtonVariant.PRIMARY}
                                    type={ButtonType.SUBMIT}
                                    size={ButtonSize.SM}
                                    disabled={updateMutation.isPending}
                                >
                                    {updateMutation.isPending ? 'Αποθήκευση...' : 'Αποθήκευση'}
                                </Button>
                            </div>
                        </form>
                    </HeadlessDialog.Panel>
                </div>
            </HeadlessDialog>
        </>
    );
};

export default EditTicketDialog;
