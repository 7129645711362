import {Fragment} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {Bell, ExternalLink, Trash} from 'lucide-react';
import {useNavigate} from 'react-router-dom';
import {TimeFormatted} from '@/components/ui/Time/TimeFormatted';
import {Badge} from '@/components/ui/Badge';
import {useGetUserNotificationsQuery} from "@/components/features/notifications/services/notifications.queries";
import Button from "@/components/ui/Button";
import {ButtonSize, ButtonVariant} from "@/components/ui/Button/ButtonSize";
import {
    useDeleteNotificationStatusMutation
} from "@/components/features/notifications/services/notifications.mutations";

const NotificationIndicator = ({ count }: { count: number }) => {
    if (count === 0) return null;

    return (
        <Badge
            className="absolute -top-1 -right-1 h-5 w-5 flex items-center justify-center bg-blue-600 text-white rounded-full text-xs"
        >
            {count > 99 ? '99+' : count}
        </Badge>
    );
};

const NotificationType = ({ type }: { type: string }) => {
    const getTypeColor = (type: string) => {
        switch (type.toLowerCase()) {
            case 'ticket':
                return 'bg-blue-100 text-blue-50';
            case 'case':
                return 'bg-purple-100 text-purple-50';
            case 'mention':
                return 'bg-orange-100 text-orange-50';
            default:
                return 'bg-gray-100 text-gray-50';
        }
    };

    return (
        <Badge className={`${getTypeColor(type)} px-2 py-0.5 text-xs font-medium rounded-full`}>
            {type}
        </Badge>
    );
};

export default function NotificationsDropdown() {
    const navigate = useNavigate();
    const {mutateAsync: deleteNotificationStatus} = useDeleteNotificationStatusMutation();

    const { data, isLoading } = useGetUserNotificationsQuery({
        page_number: 1,
        page_size: 10,
        is_seen: false
    });

    const unreadCount = data?.total_results ?? 0;

    const handleViewAll = (e: React.MouseEvent) => {
        e.preventDefault();
        navigate('/notifications');
    };

    const handleDelete = async (e: React.MouseEvent, id: string) => {
        e.stopPropagation();
        try {
            await deleteNotificationStatus({id});
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Menu as="div" className="relative z-50">
            <Menu.Button as={Fragment}>
                <Button
                    variant={ButtonVariant.GHOST}
                    size={ButtonSize.SM}
                    className="relative border-0 shadow-none hover:bg-gray-100 transition-colors duration-200"
                >
                    <Bell className="h-6 w-6 text-gray-600 hover:text-gray-800" />
                    <NotificationIndicator count={unreadCount} />
                </Button>
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-150"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-80 md:w-96 origin-top-right rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {/* Enhanced Header Section */}
                    <div className="flex items-center justify-between p-4">
                        <div className="flex items-center gap-2">
                            <Bell className="h-5 w-5 text-primary-500" />
                            <h2 className="text-base font-semibold text-gray-900">Ειδοποιήσεις</h2>
                        </div>
                        {unreadCount > 0 && (
                            <Badge className="bg-primary-600 text-primary-50 px-2 py-1 text-xs">
                                {unreadCount} νέες
                            </Badge>
                        )}
                    </div>

                    <div className="border-t border-gray-100" />

                    {/* Enhanced Notifications List */}
                    <div className="max-h-[400px] overflow-y-auto">
                        {isLoading ? (
                            <div className="p-8 flex flex-col items-center justify-center">
                                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600" />
                                <p className="mt-2 text-sm text-gray-500">Φόρτωση ειδοποιήσεων...</p>
                            </div>
                        ) : data?.data && data.data.length > 0 ? (
                            data.data.map((notification) => (
                                <Menu.Item key={notification.notification_recipient_id}>
                                    {({ active }) => (
                                        <div
                                            className={`${
                                                active ? 'bg-gray-50' : ''
                                            } p-4 cursor-pointer hover:bg-gray-50 transition-colors duration-150 border-l-4 ${
                                                notification.is_seen ? 'border-transparent' : 'border-primary-500'
                                            } relative group`}
                                        >
                                            <div className="flex flex-col gap-2">
                                                <div className="flex items-center justify-between">
                                                    <NotificationType
                                                        type={notification.notification.notification_type.notification_type_name}
                                                    />
                                                    <div className="flex items-center gap-2">
                                                        <TimeFormatted
                                                            date={notification.created_at}
                                                            className="text-xs text-gray-500"
                                                        />
                                                        <button
                                                            onClick={(e) => handleDelete(e, notification.notification_recipient_id)}
                                                            className="p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-150 hover:bg-gray-100"
                                                        >
                                                            <Trash className="w-4 h-4 text-red-400 hover:text-red-600" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <p className={`text-sm ${notification.is_seen ? 'text-gray-600' : 'text-gray-900 font-medium'}`}>
                                                    {notification.notification.description}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Item>
                            ))
                        ) : (
                            <div className="p-8 text-center">
                                <div className="mx-auto w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center mb-3">
                                    <Bell className="h-6 w-6 text-gray-400" />
                                </div>
                                {/*<p className="text-sm font-medium text-gray-900">Όλα εντάξει!</p>*/}
                                <p className="text-sm text-gray-500 mt-1">Δεν υπάρχουν νέες ειδοποιήσεις</p>
                            </div>
                        )}
                    </div>

                    <div className="border-t border-gray-100" />

                    {/* Enhanced Footer Section */}
                    <Menu.Item>
                        {({ active }) => (
                            <button
                                onClick={handleViewAll}
                                className={`${
                                    active ? 'bg-gray-50' : ''
                                } flex items-center justify-center w-full p-4 text-sm font-medium text-primary-600 hover:text-primary-700 transition-colors duration-150`}
                            >
                                Προβολή όλων των ειδοποιήσεων
                                <ExternalLink className="ml-2 h-4 w-4" />
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
