import {PRODUCT_KEYS} from "@/components/features/products/services/products.queryKeys";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {productsApi} from "@/components/features/products/services/products.api";
import {ProductFormFields} from "@/types/product.types";

export const useCreateProductMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: productsApi.create,
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: PRODUCT_KEYS.all});
        }
    });
};

export const useUpdateProductMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ id, data }: { id: string; data: ProductFormFields }) =>
            productsApi.update(id, data),
        onSuccess: async (_, { id }) => {
    try {
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: PRODUCT_KEYS.all }),
            queryClient.invalidateQueries({ queryKey: PRODUCT_KEYS.detail(id) })
        ]);
    } catch (error) {
        console.error("Error invalidating queries:", error);
    }
}
    });
};

export const useDeleteProductMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: productsApi.delete,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: PRODUCT_KEYS.all });
        }
    });
};
