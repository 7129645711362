// src/pages/CompanyDetails.tsx
import React, { useMemo, useState } from 'react';
import {
    Briefcase,
    Building2,
    Clock,
    Eye, LucideIcon,
    Mail,
    MapPin,
    Package,
    Pencil,
    Phone,
    RefreshCcw,
    Search,
    Trash2,
    Users
} from 'lucide-react';
import Button from '@/components/ui/Button';
import { ButtonSize, ButtonVariant } from '@/components/ui/Button/ButtonSize';
import { useNavigate, useParams } from "react-router-dom";
import {
    useGetCompanyProductsQuery,
    useGetCompanyQuery
} from "@/components/features/companies/services/companies.queries";
import TabsComponent from "@/components/ui/TabsComponent";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/Card";
import {
    useAssignProductsMutation,
    useDeleteCompanyProductMutation
} from "@/components/features/companies/services/companies.mutations";
import { PageHeader } from "@/components/layouts/PageLayout/PageHeader";
import { Badge } from "@/components/ui/Badge";
import { useGetUsersQuery } from "@/components/features/users/services/users.queries";
import DataTable from "@/components/ui/DataTable";
import type { ColumnDef } from "@tanstack/react-table";
import type { User } from "@/types/user.types";
import ColumnTitle from "@/components/ui/DataTable/ColumnTitle";
import { Avatar } from "@/components/ui/Avatar/Avatar";
import { UserStatusBadge } from "@/components/ui/Badge/UserStatusBadge";
import { ROUTES } from "@/constants/routes.constants";
import CompanySLATab from "@/components/features/companies/components/CompanySLATab";
import CompanyDepartmentsTab from "@/components/features/companies/components/CompanyDepartmentsTab";
import AssignProductModal from "@/components/features/companies/components/AssignProductModal";
import { useGetProductsQuery } from "@/components/features/products/services/products.queries";
import useUserStore from "@/stores/useUserStore";
import { PermissionGuard } from "@/components/guards/PermissionGuard";
import { isClient } from "@/utils/auth.utils";
import InputWithLabel from "@/components/ui/InputWithLabel";
import useDebounce from "@/hooks/useDebounce";
import { RoleBadge } from '@/components/ui/Badge/RoleBadge';
import {Product} from "@/types/product.types";

interface CompanyDetailsProps {
    isProfile?: boolean;
}

interface ProductsTabProps {
    companyId: string;
    availableProducts: Product[];
    onAssignClick: () => void;
}

const ProductsTab: React.FC<ProductsTabProps> = ({
                                                     companyId,
                                                     availableProducts,
                                                     onAssignClick
                                                 }) => {
    // Move the query inside the component to ensure it re-renders
    const { data: products, refetch } = useGetCompanyProductsQuery(companyId);
    const deleteMutation = useDeleteCompanyProductMutation();

    // Add refetch after successful deletion
    const handleDeleteProduct = async (productClientId: string) => {
        try {
            await deleteMutation.mutateAsync(productClientId);
            await refetch(); // Manually refetch after deletion
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    // Use the data from the query instead of props
    const displayProducts = products || [];

    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <div>
                        <CardTitle className="flex items-center">
                            <Package className="w-5 h-5 mr-2 text-gray-500"/>
                            Προϊόντα & Υπηρεσίες
                        </CardTitle>
                        <p className="mt-1 text-sm text-gray-500">
                            Προϊόντα και υπηρεσίες που χρησιμοποιεί η εταιρεία
                        </p>
                    </div>
                    <PermissionGuard requiredPermissions={['manage:products']}>
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            onClick={onAssignClick}
                            disabled={availableProducts?.length === 0}
                        >
                            <Package className="h-4 w-4 mr-2"/>
                            Ανάθεση Προϊόντος
                            {availableProducts?.length > 0 && (
                                <span className="ml-2 px-2 py-0.5 bg-primary-100 text-primary-700 rounded-full text-xs">
                                    {availableProducts.length}
                                </span>
                            )}
                        </Button>
                    </PermissionGuard>
                </div>
            </CardHeader>
            <CardContent>
                {displayProducts.length === 0 ? (
                    <div className="text-center py-6 text-gray-500">
                        <Package className="mx-auto h-12 w-12 text-gray-400"/>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">Δεν υπάρχουν προϊόντα</h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Ξεκινήστε αναθέτοντας προϊόντα στην εταιρεία.
                        </p>
                        <div className="mt-6">
                            <PermissionGuard requiredPermissions={['manage:products']}>
                                <Button
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                    onClick={onAssignClick}
                                    disabled={availableProducts?.length === 0}
                                >
                                    <Package className="h-4 w-4 mr-2"/>
                                    Ανάθεση Προϊόντος
                                </Button>
                            </PermissionGuard>
                        </div>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {displayProducts.map((companyProduct) => (
                            <div
                                key={companyProduct.product_client_id}
                                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
                            >
                                <div className="flex items-center space-x-4">
                                    <div className="p-2 bg-white rounded">
                                        <Package className="w-5 h-5 text-gray-500"/>
                                    </div>
                                    <div>
                                        <h4 className="font-medium text-gray-900">
                                            {companyProduct.product.product_name}
                                        </h4>
                                        <p className="text-sm text-gray-500">
                                            {companyProduct.product.product_type.product_type_name}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <Badge variant="outline" className="bg-primary-50">
                                        Κόστος: {companyProduct.price.toLocaleString('el-GR', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}
                                    </Badge>
                                    <PermissionGuard requiredPermissions={['manage:products']}>
                                        <Button
                                            variant={ButtonVariant.GHOST}
                                            size={ButtonSize.SM}
                                            onClick={() => handleDeleteProduct(companyProduct.product_client_id)}
                                            className="text-red-600 hover:text-red-700"
                                            title="Αφαίρεση"
                                        >
                                            <Trash2 className="h-4 w-4" />
                                        </Button>
                                    </PermissionGuard>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

const UsersTabContent: React.FC<{ companyId: string }> = ({ companyId }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearch = useDebounce(searchTerm, 500);

    const [userFilters, setUserFilters] = React.useState({
        page_number: 1,
        page_size: 10,
        company_id: companyId,
        user_search: undefined as string | undefined
    });

    React.useEffect(() => {
        setUserFilters(prev => ({
            ...prev,
            user_search: debouncedSearch || undefined,
            page_number: 1 // Reset to first page on search
        }));
    }, [debouncedSearch]);

    const { data: usersData, isLoading: isUsersLoading, refetch } = useGetUsersQuery(userFilters);

    const handlePageChange = (pageIndex: number, pageSize: number) => {
        setUserFilters(prev => ({
            ...prev,
            page_number: pageIndex + 1,
            page_size: pageSize
        }));
    };

    const handleRefresh = async () => {
        await refetch();
    };

    const columns = useMemo<ColumnDef<User, string>[]>(() => [
        {
            id: 'user',
            header: ({ column }) => <ColumnTitle title="Χρήστης" isSortable column={column}/>,
            accessorFn: (row) => `${row.first_name} ${row.last_name}`,
            cell: ({ row }) => (
                <div className="flex items-center gap-3">
                    <Avatar
                        user={row.original}
                        size="md"
                        className="flex-shrink-0"
                    />
                    <div className="flex flex-col">
                        <span className="font-medium text-gray-900">
                            {row.original.first_name} {row.original.last_name}
                        </span>
                        <span className="text-sm text-gray-500 flex items-center">
                            <Mail className="w-3 h-3 mr-1"/>
                            {row.original.email_address}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            id: 'role',
            header: ({ column }) => <ColumnTitle title="Ρόλος" isSortable column={column}/>,
            cell: ({ row }) => <RoleBadge role={row.original.role}/>,
        },
        {
            id: 'department',
            header: ({ column }) => <ColumnTitle title="Τμήμα" isSortable column={column}/>,
            accessorFn: (row) => row.department?.department_name ?? '-',
        },
        {
            id: 'status',
            header: ({ column }) => <ColumnTitle title="Κατάσταση" isSortable column={column}/>,
            cell: ({ row }) => <UserStatusBadge blocked={row.original.blocked}/>
        },
        {
            id: 'actions',
            header: '',
            cell: ({ row }) => {
                const user = row.original;
                return (
                    <div className="flex justify-end gap-2" onClick={e => e.stopPropagation()}>
                        <Button
                            variant={ButtonVariant.GHOST}
                            size={ButtonSize.SM}
                            onClick={() => navigate(ROUTES.USERS.DETAILS(user.user_id))}
                            title="Προβολή"
                        >
                            <Eye className="h-4 w-4"/>
                        </Button>
                    </div>
                );
            }
        }
    ], [navigate]);

    return (
        <Card>
            <CardHeader>
                <div className="flex items-center justify-between">
                    <CardTitle className="flex items-center">
                        <Users className="w-5 h-5 mr-2 text-gray-500"/>
                        Χρήστες Εταιρίας
                    </CardTitle>
                    <div className="flex items-center gap-4">
                        <InputWithLabel
                            placeholder="Αναζήτηση χρήστη..."
                            value={searchTerm}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                            icon={Search}
                            className="w-64"
                        />
                        <Button
                            variant={ButtonVariant.OUTLINE}
                            size={ButtonSize.SM}
                            className="flex items-center"
                            onClick={handleRefresh}
                            disabled={isUsersLoading}
                        >
                            <RefreshCcw className={`w-4 h-4 mr-2 ${isUsersLoading ? 'animate-spin' : ''}`}/>
                            Ανανέωση
                        </Button>
                    </div>
                </div>
            </CardHeader>
            <CardContent>
                <DataTable
                    columns={columns}
                    data={usersData?.data ?? []}
                    isLoading={isUsersLoading}
                    serverPagination={usersData ? {
                        totalPages: usersData.total_pages,
                        totalResults: usersData.total_results,
                        onPageChange: handlePageChange
                    } : undefined}
                    initialState={{
                        pagination: {
                            pageSize: userFilters.page_size,
                            pageIndex: userFilters.page_number - 1
                        }
                    }}
                />
            </CardContent>
        </Card>
    );
};

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ isProfile = false }) => {
    const navigate = useNavigate();
    const { id = "" } = useParams<{ id: string }>();
    const userStore = useUserStore(state => state.user);
    const companyId = isProfile ? userStore.company_id : id;
    const isClientUser = isClient(userStore.roles);

    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const assignMutation = useAssignProductsMutation();

    const { data: company, isLoading } = useGetCompanyQuery(companyId);
    const { data: companyProducts } = useGetCompanyProductsQuery(companyId);
    const { data: allProducts = [] } = useGetProductsQuery();

    // Filter out products already assigned to company
    const availableProducts = useMemo(() => {
        if (!companyProducts || !allProducts) return [];

        const assignedProductIds = companyProducts.map(
            cp => cp.product.product_id
        );

        return allProducts.filter(
            product => !assignedProductIds.includes(product.product_id)
        );
    }, [allProducts, companyProducts]);

    const tabs = useMemo(() => [
        {
            name: 'Τμήματα',
            icon: Briefcase,
            content: <CompanyDepartmentsTab companyId={companyId} />
        },
        {
            name: 'Χρήστες',
            icon: Users,
            content: <UsersTabContent companyId={companyId} />
        },
        ...((!isClientUser) ? [
            {
                name: 'Προϊόντα',
                icon: Package,
                content: (
                    <ProductsTab
                        companyId={companyId}
                        availableProducts={availableProducts}
                        onAssignClick={() => setAssignModalOpen(true)}
                    />
                )
            },
            {
                name: 'SLA',
                icon: Clock,
                content: <CompanySLATab companyId={companyId}/>
            }
        ] : [])
    ], [companyId, isClientUser, availableProducts]);

    const InfoItem: React.FC<{ icon: LucideIcon; label: string; value?: string }> = ({ icon: Icon, label, value }) => (
        <div className="flex items-center space-x-3 p-4 rounded-lg bg-gray-50">
            <div className="flex-shrink-0">
                <div className="p-2 bg-white rounded-lg">
                    <Icon className="w-5 h-5 text-gray-600"/>
                </div>
            </div>
            <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900">{value || '-'}</p>
                <p className="text-sm text-gray-500">{label}</p>
            </div>
        </div>
    );

    const editCompany = () => {
        navigate(`/companies/${id}/edit`);
    };

    if (isLoading) return null;

    return (
        <div className="min-h-screen bg-gray-100">
            <PageHeader
                title={company?.company_name || ''}
                description={isProfile ? "Η Εταιρία μου" : "Προβολή και διαχείριση Εταιρίας"}
                backButton={!isProfile}
                actions={
                    <PermissionGuard requiredPermissions={['manage:companies']}>
                        <div className="flex items-center space-x-3">
                            {!isProfile && (
                                <Button
                                    onClick={editCompany}
                                    variant={ButtonVariant.OUTLINE}
                                    size={ButtonSize.SM}
                                >
                                    <Pencil className="w-4 h-4 mr-2"/>
                                    Επεξεργασία
                                </Button>
                            )}
                        </div>
                    </PermissionGuard>
                }
            />

            <main className="max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8">
                <div className="space-y-6">
                    <Card>
                        <CardHeader>
                            <CardTitle>Στοιχεία Εταιρίας</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                <InfoItem
                                    icon={Building2}
                                    label="Εταιρεία"
                                    value={company?.company_name}
                                />
                                <InfoItem
                                    icon={MapPin}
                                    label="Διεύθυνση"
                                    value={company?.address}
                                />
                                <InfoItem
                                    icon={Phone}
                                    label="Τηλέφωνο Επικοινωνίας"
                                    value={company?.phone_number}
                                />
                                <InfoItem
                                    icon={Mail}
                                    label="Email"
                                    value={company?.email_address}
                                />
                            </div>
                        </CardContent>
                    </Card>

                    <TabsComponent
                        tabs={tabs}
                        fullWidth
                        className="bg-white rounded-lg shadow"
                    />
                </div>
            </main>

            <AssignProductModal
                open={assignModalOpen}
                setOpen={setAssignModalOpen}
                companyId={companyId}
                products={availableProducts}
                onAssign={async (productIds, price) => {
                    await assignMutation.mutateAsync({
                        companyId: companyId,
                        productIds,
                        price
                    });
                }}
                isLoading={assignMutation.isPending}
            />
        </div>
    );
};

export default CompanyDetails;
