import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/el';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

interface TimeAgoProps {
    date: string | Date;
    className?: string;
    shortFormat?: boolean;
}

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale('el');

// Keep the custom month abbreviations to maintain exact formatting
const GREEK_MONTHS_SHORT = [
    'Ιαν', 'Φεβ', 'Μαρ', 'Απρ', 'Μαϊ', 'Ιουν',
    'Ιουλ', 'Αυγ', 'Σεπ', 'Οκτ', 'Νοε', 'Δεκ'
];

const parseDate = (date: string | Date): Date => {
    if (date instanceof Date) return date;
    const dateString = date.endsWith('Z') ? date : `${date}Z`;
    // Parse as UTC, then convert to Athens time
    return dayjs.utc(dateString).tz('Europe/Athens').toDate();
};

const formatDateTime = (date: Date): string => {
    // Create dayjs object in Athens timezone
    const dayjsDate = dayjs(date).tz('Europe/Athens');
    const day = dayjsDate.format('DD');
    const month = GREEK_MONTHS_SHORT[dayjsDate.month()];
    const year = dayjsDate.year();
    const time = dayjsDate.format('HH:mm');

    return `${day} ${month} ${year} - ${time}`;
};

const formatFullDateTime = (date: Date): string => {
    return dayjs(date)
        .tz('Europe/Athens')
        .locale('el')
        .format('D MMMM YYYY - HH:mm');
};

export const TimeFormatted: React.FC<TimeAgoProps> = ({ date, className }) => {
    try {
        const parsedDate = parseDate(date);
        if (!dayjs(parsedDate).isValid()) {
            return <time className={className}>μη έγκυρη ημερομηνία</time>;
        }

        const formattedDate = formatDateTime(parsedDate);
        const fullDateTime = formatFullDateTime(parsedDate);

        return (
            <time
                className={className}
                title={fullDateTime}
                dateTime={dayjs(parsedDate).toISOString()}
                aria-label={`Δημοσιεύτηκε ${fullDateTime}`}
            >
                {formattedDate}
            </time>
        );
    } catch (error) {
        console.error('Error formatting date:', error);
        return <time className={className}>μη έγκυρη ημερομηνία</time>;
    }
};
