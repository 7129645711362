import {useMutation, useQueryClient} from "@tanstack/react-query";
import {usersApi} from "@/components/features/users/services/users.api";
import {toast} from "react-toastify";
import {USER_KEYS} from "@/components/features/users/services/users.queryKeys";
import type {ActionStatus} from "@/types/api.types";
import {useNavigate} from "react-router-dom";
import {UpdateUserRequest} from "@/types/user.types";

export const useCreateUserMutation = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation<ActionStatus, Error, UpdateUserRequest>({
        mutationFn: usersApi.create,
        onSuccess: async (response) => {
            if (response.action_status) {
                toast.success('Ο χρήστης δημιουργήθηκε επιτυχώς');
                await queryClient.invalidateQueries({queryKey: USER_KEYS.all});
                navigate(-1);
            } else {
                toast.error(response.action_message);
            }
            return response;
        },
        onError: (error) => {
            toast.error('Σφάλμα κατά τη δημιουργία του χρήστη');
            console.error('Create user error:', error);
        }
    });
};

export const useUpdateUserMutation = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation<ActionStatus, Error, {id: string, user: UpdateUserRequest}>({
        mutationFn: ({id, user}) => usersApi.update(id, user),
        onSuccess: async (response, {id}) => {
            if (response.action_status) {
                toast.success('Ο χρήστης ενημερώθηκε επιτυχώς');
                await Promise.all([
                    await queryClient.invalidateQueries({queryKey: USER_KEYS.all}),
                    await queryClient.invalidateQueries({ queryKey: USER_KEYS.detail(id) })
                ]);
                navigate(-1);
            } else {
                toast.error(response.action_message);
            }
            return response;
        },
        onError: (error) => {
            toast.error('Σφάλμα κατά τη ενημέρωση του χρήστη');
            console.error('Update user error:', error);
        }
    });
};

export const useDeleteUserMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<ActionStatus, Error, string>({
        mutationFn: usersApi.delete,
        onSuccess: async (response) => {
            if (response.action_status) {
                toast.success('Ο χρήστης διαγράφηκε επιτυχώς');
                await queryClient.invalidateQueries({queryKey: USER_KEYS.all});
            } else {
                toast.error(response.action_message);
            }
            return response;
        },
        onError: (error) => {
            toast.error('Σφάλμα κατά τη διαγραφή του χρήστη');
            console.error('Delete user error:', error);
        }
    });
}

export const useHeartbeatMutation = () => {
    return useMutation( {
        mutationFn: usersApi.sendHeartbeat,
        onSuccess: async () => {
        },
        retry: 3,
        retryDelay: 2000
    });
};
