import { useMutation, useQueryClient } from "@tanstack/react-query";
import { categoriesApi } from "./categories.api";
import { CATEGORY_KEYS } from "./categories.queryKeys";
import type { CategoryFormFields, CategoryResponse } from "@/types/category.types";
import type { ActionStatus } from "@/types/api.types";

export const useCreateCategoryMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<CategoryResponse, Error, CategoryFormFields>({
        mutationFn: (data) => categoriesApi.create(data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: CATEGORY_KEYS.all });
        },
    });
};

export const useUpdateCategoryMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<
        ActionStatus,
        Error,
        { id: string; category: CategoryFormFields }
    >({
        mutationFn: ({ id, category }) => categoriesApi.update({ id, category }),
        onSuccess: async (_, variables) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: CATEGORY_KEYS.all }),
                queryClient.invalidateQueries({ queryKey: CATEGORY_KEYS.detail(variables.id) })
            ]);
        },
    });
};

export const useDeleteCategoryMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<ActionStatus, Error, string>({
        mutationFn: (id) => categoriesApi.delete(id),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: CATEGORY_KEYS.all });
        },
    });
};
