import React from 'react';
import { FileText, Info, Package, User, Paperclip } from 'lucide-react';
import Button from '@/components/ui/Button';
import {ButtonSize, ButtonType, ButtonVariant} from '@/components/ui/Button/ButtonSize';
import InputWithLabel from '@/components/ui/InputWithLabel';
import TextArea from '@/components/ui/TextArea';
import CategoryDropdown from "@/components/ui/DropdownWithLabel/CategoryDropdown";
import PriorityDropdown from "@/components/ui/DropdownWithLabel/PriorityDropdown";
import CompanyDropdown from "@/components/ui/DropdownWithLabel/CompanyDropdown";
import UserDropdown from "@/components/ui/DropdownWithLabel/UserDropdown";
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '@/constants/routes.constants';
import useUserStore from "@/stores/useUserStore";
import {isClient, isMindsys, isMindsysExternalPartner} from "@/utils/auth.utils";
import {useGetCompanyProductsQuery} from "@/components/features/companies/services/companies.queries";
import {ProductCheckboxGroup} from "@/components/ui/Checkbox/ProductCheckboxGroup";
import { useCreateTicketMutation } from '@/components/features/tickets/services/tickets.mutations';
import { toast } from 'react-toastify';
import { FileUpload } from '@/components/ui/FileUpload/FileUpload';
import {messagesApi} from "@/components/features/messages/services/messages.api";
import {useGetCategoriesQuery} from "@/components/features/categories/services/categories.queries";

interface FormData {
    title: string;
    description: string;
    category_id: string;
    priority_id: string;
    company_id?: string;
    contact_id?: string;
    product_ids: string[];
}

const CreateTicket = () => {
    const navigate = useNavigate();
    const userStore = useUserStore(state => state.user);
    const createTicketMutation = useCreateTicketMutation();

    const isClientUser = isClient(userStore.roles) || isMindsysExternalPartner(userStore.roles);
    const isMindsysUser = isMindsys(userStore.roles);
    const isClientEmployee = userStore.roles.includes('client_employee');


    const userId = userStore.user_id;
    const userCompanyId = userStore.company_id;
    const { data: categories} = useGetCategoriesQuery();
    const defaultCategoryName = 'Technical Support';

    React.useEffect(() => {
        if (categories) {
            const defaultCategoryItem = categories.find(
                category => category.category_name.includes(defaultCategoryName)
            );
            const defaultCategoryId = defaultCategoryItem?.category_id;

            if (defaultCategoryId) {
                setFormData(prev => ({
                    ...prev,
                    category_id: defaultCategoryId
                }));
            }
        }
    }, [categories]);

    const [formData, setFormData] = React.useState<FormData>({
        title: '',
        description: '',
        category_id: '',
        priority_id: '',
        company_id: isClientUser ? userCompanyId : undefined,
        contact_id: isClientUser ? userId : undefined,
        product_ids: [],
    });

    const [selectedProducts, setSelectedProducts] = React.useState<string[]>([]);
    const [files, setFiles] = React.useState<File[]>([]);
    const [showFileUpload, setShowFileUpload] = React.useState(false);

    const { data: products, isLoading: isLoadingProducts } = useGetCompanyProductsQuery(
        formData.company_id ?? ''
    );

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            // Step 1: Create the ticket
            const ticketData = {
                title: formData.title,
                description: formData.description,
                category_id: formData.category_id,
                priority_id: formData.priority_id,
                company_id: formData.company_id ?? userCompanyId,
                contact_id: formData.contact_id ?? userId,
                products: selectedProducts,
            };

            // console.log('ticketData:', ticketData);

            const ticketResponse = await createTicketMutation.mutateAsync(ticketData);

            if (!ticketResponse.action_status || !ticketResponse.ticket_id) {
                toast.error(ticketResponse.action_message || 'Σφάλμα κατά τη δημιουργία του αιτήματος');
                return;
            }

            // Step 2: If there are files, create a message with attachments
            if (files.length > 0) {
                // Create a new message mutation instance with the ticket ID directly
                const messageData = {
                    message_content: "Συνημμένα",
                    is_internal: false,
                    files: files
                };

                const messageResponse = await messagesApi.create(ticketResponse.ticket_id, messageData)

                if (!messageResponse.action_status) {
                    toast.warning('Το αίτημα δημιουργήθηκε, αλλά υπήρξε πρόβλημα με τα συνημμένα');
                }
            }

            navigate(ROUTES.TICKETS.LIST);
        } catch (error) {
            console.error('Error creating ticket:', error);
            toast.error('Σφάλμα κατά τη δημιουργία του αιτήματος');
        }
    };

    React.useEffect(() => {
        if (isMindsysUser) {
            setFormData(prev => ({
                ...prev,
                contact_id: undefined
            }));
            setSelectedProducts([]);
        }
    }, [formData.company_id, isMindsysUser]);

    return (
        <form onSubmit={handleSubmit} className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
            {/* Header */}
            <div className="mb-6 pt-8 flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <FileText className="h-8 w-8 text-primary-500" />
                    <div>
                        <h1 className="text-2xl font-semibold text-gray-900">Δημιουργία Νέου Αιτήματος</h1>
                        <p className="mt-1 text-sm text-gray-500">
                            Δημιουργήστε ένα νέο αίτημα υποστήριξης
                        </p>
                    </div>
                </div>
            </div>

            <div className="space-y-6 pb-8">

                {/* Basic Information */}
                <div className="bg-surface shadow rounded-lg">
                    <div className="p-6">
                        <div className="flex items-center gap-3 mb-6">
                            <Info className="h-5 w-5 text-gray-400"/>
                            <h2 className="text-lg font-medium text-gray-900">Βασικές Πληροφορίες</h2>
                        </div>
                        <div className="space-y-6">

                            {/* Title */}
                            <InputWithLabel
                                label="Τίτλος"
                                required
                                maxLength={500}
                                placeholder="Σύντομη περιγραφή του ζητήματος"
                                value={formData.title}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    title: e.target.value
                                }))}
                            />

                            {/* Description */}
                            <TextArea
                                label="Περιγραφή"
                                required
                                rows={4}
                                maxLength={1000}
                                placeholder="Αναλυτική περιγραφή του αιτήματος ή του προβλήματός σας"
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    description: e.target.value
                                }))}
                            />

                            {/* Category & Priority */}
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                {!isClientEmployee && (
                                    <div className="space-y-4">
                                        <CategoryDropdown
                                            required
                                            value={formData.category_id}
                                            onChange={(value) => setFormData(prev => ({
                                                ...prev,
                                                category_id: value
                                            }))}
                                            defaultCategory="Technical Support"
                                        />
                                    </div>
                                )}
                                <div className={`space-y-4 ${isClientEmployee ? 'sm:col-span-2' : ''}`}>
                                    <PriorityDropdown
                                        required
                                        value={formData.priority_id}
                                        onChange={(value) => setFormData(prev => ({
                                            ...prev,
                                            priority_id: value
                                        }))}
                                    />
                                </div>
                            </div>

                            {/* Company & Contact */}
                            {isMindsysUser && (
                                <div>
                                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                        <CompanyDropdown
                                            required
                                            value={formData.company_id}
                                            onChange={(companyId) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    company_id: companyId,
                                                    contact_id: undefined
                                                }));
                                            }}
                                        />
                                        {formData.company_id ? (
                                            <UserDropdown
                                                key={formData.company_id}
                                                required
                                                company_id={formData.company_id}
                                                value={formData.contact_id}
                                                onChange={(userId) => setFormData(prev => ({
                                                    ...prev,
                                                    contact_id: userId
                                                }))}
                                                filterBlocked={true}
                                            />
                                        ) : (
                                            <InputWithLabel
                                                label="Αναφορά Αιτήματος από"
                                                required
                                                placeholder="Πρώτα επιλέξτε εταιρεία"
                                                icon={User}
                                                disabled
                                            />
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Add File Upload Section */}
                            <div className="pt-4">
                                <div className="flex items-center justify-between mb-4">
                                    <div className="flex items-center gap-2">
                                        <Paperclip className="h-5 w-5 text-gray-400"/>
                                        <h3 className="text-lg font-medium text-gray-900">Συνημμένα</h3>
                                    </div>
                                    <Button
                                        variant={ButtonVariant.GHOST}
                                        size={ButtonSize.SM}
                                        onClick={() => setShowFileUpload(!showFileUpload)}
                                        type={ButtonType.BUTTON}
                                    >
                                        {showFileUpload ? 'Απόκρυψη' : 'Προσθήκη Συνημμένων'}
                                    </Button>
                                </div>

                                {showFileUpload && (
                                    <FileUpload
                                        onFilesChange={setFiles}
                                        existingFiles={files}
                                        maxSize={20}
                                    />
                                )}

                                {!showFileUpload && files.length > 0 && (
                                    <div className="bg-gray-50 rounded-lg p-4">
                                        <p className="text-sm text-gray-600">
                                            {files.length} {files.length === 1 ? 'συνημμένο' : 'συνημμένα'}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Products Section */}
                            {isMindsysUser && (
                                <>
                                    <div className="flex items-center mb-4 pt-5">
                                        <Package className="h-5 w-5 text-gray-400 mr-2"/>
                                        <h3 className="text-lg font-medium text-gray-900">Σχετικά Προϊόντα</h3>
                                    </div>
                                    {formData.company_id ? (
                                        <ProductCheckboxGroup
                                            isLoading={isLoadingProducts}
                                            products={products ?? []}
                                            selectedProducts={selectedProducts}
                                            onChange={setSelectedProducts}
                                        />
                                    ) : (
                                        <div className="bg-surface border border-dashed border-gray-300 rounded-lg p-6">
                                            <div className="text-center">
                                                <p className="text-sm text-gray-500">
                                                    Πρώτα επιλέξτε εταιρεία για να εμφανιστούν τα προϊόντα
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {/* Submit Section */}
                <div className="flex items-center justify-end space-x-4">
                    <Button
                        variant={ButtonVariant.OUTLINE}
                        size={ButtonSize.LG}
                        className="px-6"
                        onClick={() => navigate(ROUTES.TICKETS.LIST)}
                        type={ButtonType.BUTTON}
                        disabled={createTicketMutation.isPending}
                    >
                        Ακύρωση
                    </Button>
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LG}
                        className="px-6"
                        type={ButtonType.SUBMIT}
                        disabled={createTicketMutation.isPending}
                    >
                        {(createTicketMutation.isPending)
                            ? 'Δημιουργία...'
                            : 'Δημιουργία Αιτήματος'}
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default CreateTicket;
